$(function () {
  // console.log('homepage');

  if ($(window).width() > 991) {
    if ($('.mainNews [data-hidden-link]').length > 3) {
      let listItems = $('.mainNews [data-hidden-link] li');

      listItems.each(function (index, element) {
        if (index > 2) {
          $(element).addClass('hideDesktop');
        }
      })
    }
  }
});